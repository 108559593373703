import $ from "jquery";

const order = {
    poll_paid_timer: null,

    triggerCallback(callback, args) {
        if (typeof callback == "function") {
            callback(args);
        }
    },

    clearPollTimer() {
        if (order.poll_paid_timer !== null) {
            clearTimeout(order.poll_paid_timer);
        }
    },

    pollStatus(
        id,
        interval,
        accepted_status_array,
        callback_waiting,
        callback_paid,
    ) {
        order.clearPollTimer();

        order.poll_paid_timer = setTimeout(
            () => {
                order.getStatus(
                    id,
                    (response) => {
                        order.clearPollTimer();
                        const status_array = JSON.parse(accepted_status_array);
                        if (status_array.includes(response.status)) {
                            order.triggerCallback(callback_paid, response);
                        } else {
                            order.triggerCallback(callback_waiting, response);
                            order.pollStatus(
                                id,
                                interval,
                                accepted_status_array,
                                callback_waiting,
                                callback_paid,
                            );
                        }
                    },
                    (e) => {
                        order.clearPollTimer();
                    },
                );
            },
            interval,
            id,
            interval,
            accepted_status_array,
            callback_waiting,
            callback_paid,
        );
    },

    getStatus(id, success, fail) {
        const url = `/ajax/order/${id}/status`;
        const data = {};
        $.ajax({
            method: "GET",
            url,
            data,
        })
            .done((response) => {
                order.triggerCallback(success, response);
            })
            .fail((e) => {
                order.triggerCallback(fail, e);
            });
    },
};
window.order = order;
