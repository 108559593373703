var x, i, j, l, ll, selElmnt, a, b, c;

x = document.getElementsByClassName("lux-select-dropdown");
l = x.length;

for (i = 0; i < l; i++) {
    selElmnt = x[i].getElementsByTagName("select")[0];
    var selClasses = selElmnt.classList.value;
    ll = selElmnt.length;

    /* For each element, create a new DIV that will act as the selected item: */
    a = document.createElement("div");
    a.setAttribute("class", "select-selected " + selClasses);
    a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
    x[i].appendChild(a);

    /* For each element, create a new DIV that will contain the option list: */
    b = document.createElement("div");
    b.setAttribute("class", "select-items select-hide " + selClasses);

    // Calculate the width of the widest option
    var widestWidth = 0;
    for (j = 0; j < ll; j++) {
        var tempDiv = document.createElement("div");
        tempDiv.style.position = "absolute";
        tempDiv.style.visibility = "hidden";
        tempDiv.style.whiteSpace = "nowrap";
        tempDiv.innerHTML = selElmnt.options[j].innerHTML;
        document.body.appendChild(tempDiv);
        widestWidth = Math.max(widestWidth, tempDiv.offsetWidth);
        document.body.removeChild(tempDiv);

        c = document.createElement("div");
        c.innerHTML = selElmnt.options[j].innerHTML;

        // If the option is disabled, add the 'option-disabled' class
        if (selElmnt.options[j].disabled) {
            c.classList.add("option-disabled");
        }

        // Add a special class if this option is initially selected
        if (j === selElmnt.selectedIndex) {
            c.classList.add("same-as-selected");
        }

        c.addEventListener("click", function (e) {
            if (this.classList.contains("option-disabled")) {
                return;
            }

            var y, i, k, s, h, sl, yl;
            s = this.parentNode.parentNode.getElementsByTagName("select")[0];
            sl = s.length;
            h = this.parentNode.previousSibling;

            for (i = 0; i < sl; i++) {
                if (s.options[i].innerHTML == this.innerHTML) {
                    s.selectedIndex = i;
                    h.innerHTML = this.innerHTML;

                    // Trigger the change event on the native select element
                    var event = new Event("change");
                    s.dispatchEvent(event);

                    y =
                        this.parentNode.getElementsByClassName(
                            "same-as-selected",
                        );
                    yl = y.length;
                    for (k = 0; k < yl; k++) {
                        y[k].classList.remove("same-as-selected");
                    }
                    this.setAttribute("class", "same-as-selected");
                    break;
                }
            }
            h.click();
        });
        b.appendChild(c);
    }

    // Set the widest option width as a CSS variable on the dropdown container
    x[i].style.setProperty("--widestOption", `${widestWidth}px`);

    x[i].appendChild(b);
    x[i].classList.add("initialized");

    a.addEventListener("click", function (e) {
        /* When the select box is clicked, close any other select boxes,
        and open/close the current select box: */
        e.stopPropagation();
        closeAllSelect(this);
        this.nextSibling.classList.toggle("select-hide");
        this.classList.toggle("select-arrow-active");

        if (!this.nextSibling.classList.contains("select-hide")) {
            document.addEventListener("click", closeAllSelect);
        } else {
            document.removeEventListener("click", closeAllSelect);
        }
    });
}

function closeAllSelect(elmnt) {
    /* A function that will close all select boxes in the document,
    except the current select box: */

    if (elmnt.target?.classList.contains("option-disabled")) {
        return;
    }

    var x,
        y,
        i,
        xl,
        yl,
        arrNo = [];
    x = document.getElementsByClassName("select-items");
    y = document.getElementsByClassName("select-selected");
    xl = x.length;
    yl = y.length;
    for (i = 0; i < yl; i++) {
        if (elmnt == y[i]) {
            arrNo.push(i);
        } else {
            y[i].classList.remove("select-arrow-active");
        }
    }
    for (i = 0; i < xl; i++) {
        if (arrNo.indexOf(i)) {
            x[i].classList.add("select-hide");
        }
    }
}

/* If the user clicks anywhere outside the select box,
then close all select boxes: */
document.addEventListener("click", closeAllSelect);
