import * as Sentry from "@sentry/browser";

if (window.sentry_dsn) {
    Sentry.init({
        dsn: window.sentry_dsn,
        release: window.app_release,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        tracesSampleRate: 0.01,
        tracePropagationTargets: [
            /^https:\/\/www.luxplus\.be\//,
            /^https:\/\/www.luxplus\.co\.uk\//,
            /^https:\/\/www.luxplus\.dk\//,
            /^https:\/\/www.luxplus\.fi\//,
            /^https:\/\/www.luxplus\.nl\//,
            /^https:\/\/www.luxplus\.no\//,
            /^https:\/\/www.luxplus\.se\//,
        ],
        replaysSessionSampleRate: 0.01,
        replaysOnErrorSampleRate: 1.0,
        ignoreErrors: ["Non-Error promise rejection captured.+"],
    });
}
