import storage from "local-storage-fallback";
import MemoryStorage from "local-storage-fallback/lib/MemoryStorage";

function hasStorage(name) {
    try {
        const key = "TEST_KEY";
        const expected = "1";
        window[name].setItem(key, expected);
        const actual = window[name].getItem(key);
        window[name].removeItem(key);
        return expected === actual;
    } catch (e) {
        return false;
    }
}

if (!hasStorage("localStorage")) {
    Object.defineProperty(window, "localStorage", {
        value: storage,
    });
}

if (!hasStorage("sessionStorage")) {
    Object.defineProperty(window, "sessionStorage", {
        value: new MemoryStorage(),
    });
}
