function shouldLogVisit() {
    const nextVisitLogTime = localStorage.getItem("next_visit_log");
    if (!nextVisitLogTime) {
        return true;
    }

    const nextVisitLog = new Date(Number(nextVisitLogTime));
    const now = new Date();
    now.setHours(0, 0, 0, 0);

    return now >= nextVisitLog;
}

function recordVisit() {
    fetch("/tracking/log_customer_visit", {
        method: "POST",
        headers: {
            "X-CSRF-TOKEN": window.CSRF_TOKEN,
            "Content-Type": "application/json",
        },
    })
        .then((response) => {
            if (response.status === 200 || response.status === 201) {
                const tomorrow = new Date();
                tomorrow.setDate(tomorrow.getDate() + 1);
                tomorrow.setHours(0, 0, 0, 0);

                localStorage.setItem(
                    "next_visit_log",
                    tomorrow.getTime().toString(),
                );
            }
        })
        .catch((error) => {
            console.error("Error logging visit:", error);
        });
}

$(() => {
    if (shouldLogVisit()) {
        recordVisit();
    }
});
