const luxPopups = document.querySelectorAll("div.lux-popup");
const luxPopupToggles = document.querySelectorAll("*[data-toggle='lux-popup']");
const luxPopupNit = document.querySelectorAll(".lux-modal-nit, .lux-panel");
let luxToasts = document.querySelectorAll("div.lux-toast");
let luxModals = document.querySelectorAll("div.lux-modal");
let delayTimer;

const luxPopup = {
    popupClose(popup) {
        popup.classList.remove("active");
        popup.setAttribute("aria-hidden", "true");
        document.querySelector("html").classList.remove("no-scroll");
    },
    popupDismiss(popup) {
        const toggles = popup.querySelectorAll("*[data-dismiss='lux-popup']");

        toggles.forEach(function (popupDismiss) {
            popupDismiss.addEventListener("click", function () {
                luxPopup.popupClose(popup);
            });
        });
    },
    popupShow(popup) {
        popup = document.getElementById(popup);

        if (popup) {
            popup.classList.toggle("active");
            popup.setAttribute("aria-hidden", "false");

            if (
                window.innerWidth < 540 ||
                popup.classList.contains("lux-modal")
            ) {
                document.querySelector("html").classList.add("no-scroll");
            }
        }
    },
    popupDelayTimer(popup, state) {
        clearTimeout(delayTimer);
        const popupDelay = popup.getAttribute("data-delay");

        if (state === "add") {
            delayTimer = setTimeout(function () {
                popup.classList.add("active");
            }, popupDelay);
        } else {
            delayTimer = setTimeout(function () {
                popup.classList.remove("active");
            }, popupDelay);
        }
    },
    popupDelay(popup) {
        if (popup.getAttribute("data-show")) {
            if (popup.getAttribute("data-delay")) {
                if (popup.getAttribute("data-show") === "true") {
                    popup.classList.add("active");
                    this.popupDelayTimer(popup, "remove");
                } else if (popup.getAttribute("data-show") === "false") {
                    this.popupDelayTimer(popup, "add");
                }
            }
        } else if (popup.getAttribute("data-delay")) {
            this.popupDelayTimer(popup, "remove");
        }
    },
    toastShow(toast) {
        luxToasts = document.querySelectorAll(".lux-toast");

        if (toast.classList.contains("active")) {
            toast.classList.remove("active");

            for (let toast of luxToasts) {
                toast.classList.remove("active");
            }

            setTimeout(function () {
                toast.classList.add("active");
            }, 300);
        } else {
            for (let toast of luxToasts) {
                toast.classList.remove("active");
            }

            toast.classList.add("active");
        }

        this.popupDelay(toast);
        this.popupDismiss(toast);
    },
};

window.luxPopup = luxPopup;

luxPopupToggles.forEach(function (toggle) {
    toggle.addEventListener("click", function () {
        luxPopup.popupShow(toggle.getAttribute("data-target"));
    });
});

luxPopups.forEach(function (popup) {
    popup = document.getElementById(popup.getAttribute("id"));
    if (popup) {
        luxPopup.popupDismiss(popup);
    }
});

luxToasts.forEach(function (toast) {
    toast = document.getElementById(toast.getAttribute("id"));
    luxPopup.popupDelay(toast);
});

luxModals.forEach(function (modal) {
    modal = document.getElementById(modal.getAttribute("id"));
    luxPopup.popupDelay(modal);
});

let isActive = (element) => {
    return element.classList.contains("active");
};

luxPopupNit.forEach((nitPopup) => {
    document.addEventListener("keydown", function (e) {
        if (e.key === "Escape" && isActive(nitPopup)) {
            luxPopup.popupClose(nitPopup);
        }
    });

    nitPopup.addEventListener("click", function (e) {
        if (
            e.target.matches(".lux-modal-nit, .lux-panel") &&
            isActive(nitPopup)
        ) {
            luxPopup.popupClose(nitPopup);
        }
    });
});
