import $ from "jquery";
import LPEvent from "../classes/LPEvent";

class MobileMenu {
    constructor(parentSelector, sections) {
        this._currentPage = 1;
        this._settings = {
            maxPages: 3,
        };
        this.bindElements(parentSelector);
        this.processSections(sections);
    }

    bindElements(parentSelector) {
        this.$elements = {
            menu: $(parentSelector),
            slider: $(`${parentSelector} .slides`),
            header: $(`${parentSelector} .mobile-menu-header`),
            title: $(
                `${parentSelector} .mobile-menu-header .header-title .title-text`,
            ),
            backBtn: $(`${parentSelector} .mobile-menu-header .navigate-back`),
            logo: $(
                `${parentSelector} .mobile-menu-header .header-title .logo`,
            ),
        };
    }

    processSections(sections) {
        this._sections = new Array();

        for (const section of sections) {
            if (section.enabled) {
                const menuSection = new MobileMenuItem(
                    section,
                    this.$elements.menu.find(
                        `div[data-sectionid="${section.section_id}"]`,
                    ),
                );
                menuSection.onItemClicked.bind(this.onItemClicked.bind(this));
                this._sections.push(menuSection);
            }
        }
    }

    onItemClicked(e, item) {
        if (item.hasMenuItems) {
            this.renderNextPage(item);
            this.navigateForward();
        } else {
            location.href = item.slug;
        }
    }

    navigateBack() {
        if (this._currentPage > 1) {
            this.navigateToPage(this._currentPage - 1);
        }
    }

    navigateForward() {
        if (this._currentPage < this._settings.maxPages) {
            this.navigateToPage(this._currentPage + 1);
        }
    }

    navigateToPage(page) {
        this._currentPage = page;
        this.$elements.slider.removeClass("page-2 page-3");
        if (page > 1) {
            this.$elements.slider.addClass(`page-${this._currentPage}`);
        }
        this.updateHeader();
    }

    show() {
        this.$elements.menu.addClass("show");
        $("body").addClass("hasOverlay menu-open");
    }

    hide() {
        $("body").removeClass("hasOverlay menu-open");
        this.$elements.menu.removeClass("show");
    }

    get currentSlide() {
        return this.$elements.slider.find(
            `.slide[data-page="${this._currentPage}"]`,
        );
    }

    renderNextPage(item) {
        const $slide = this.currentSlide.next();
        $slide.children().remove();
        const $subHeader = this.getSubHeader(item);
        if ($subHeader) {
            $slide.append($subHeader);
        }
        for (let i = 0; i < item.menuItems.length; i++) {
            item.menuItems[i].appendTo($slide);
        }
        $slide.data("item-title", item.name);
    }

    getSubHeader({ isSubheaderDisabled, slug, name }) {
        if (isSubheaderDisabled) {
            return null;
        }
        const $elm = $(
            '<div class="mobile-menu-item mobile-menu-showall"></div>',
        );
        $elm.append(`<a href="${slug}">${window.TEXT1146} ${name}</a>`);
        return $elm;
    }

    updateHeader() {
        const headerText = this.currentSlide.data("item-title") || null;
        if (headerText) {
            this.$elements.header.addClass("hide-logo");
            this.$elements.logo.addClass("d-none");
            this.$elements.title
                .text(this.currentSlide.data("item-title") || null)
                .removeClass("d-none");
        } else {
            this.$elements.header.removeClass("hide-logo");
            this.$elements.logo.removeClass("d-none");
            this.$elements.title.text(null).addClass("d-none");
        }
        this.$elements.backBtn.toggleClass("d-none", this._currentPage < 2);
    }
}

class MobileMenuItem {
    constructor(item, existingElement) {
        if (existingElement === void 0) {
            existingElement = null;
        }
        this._item = item;
        this._onClickEvent = new LPEvent("__sectionClicked");
        if (existingElement) {
            this.setElement(existingElement);
            this.addClickListener();
        } else {
            if (this._item.renderAsHeader) {
                this.createHeaderElement();
            } else {
                this.createElement();
            }
        }
        this.processMenuItems();
    }

    processMenuItems() {
        if (this.hasMenuItems) {
            this._items = new Array();
            for (let i = 0; i < this._item.menu_items.length; i++) {
                const item = new MobileMenuItem(this._item.menu_items[i]);
                item.onItemClicked.bind(this.onSubItemClicked.bind(this));
                this._items.push(item);
            }
        }
    }

    createElement() {
        const $elm = $('<div class="mobile-menu-item"></div>');
        $elm.append(`<span class="menu-item-text">${this._item.name}</span>`);
        if (this.hasMenuItems) {
            $elm.append(
                '<span class="fa fa-angle-right mobile-menu-icon menu-item-more"></span>',
            );
        }
        this.setElement($elm);
    }

    createHeaderElement() {
        const $elm = $(
            '<div class="mobile-menu-item mobile-menu-subheader"></div>',
        );
        $elm.append(`<span class="menu-item-text">${this._item.name}</span>`);
        this.setElement($elm);
    }

    setElement(elm) {
        this.$elm = elm;
    }

    onClickFromUI(e) {
        this._onClickEvent.trigger(this);
    }

    onSubItemClicked(e, item) {
        this._onClickEvent.trigger(item);
    }

    get onItemClicked() {
        return this._onClickEvent;
    }

    get hasMenuItems() {
        return this._item.menu_items && this._item.menu_items.length > 0;
    }

    get menuItems() {
        return this._items;
    }

    get slug() {
        return this._item.slug;
    }

    get name() {
        return this._item.name;
    }

    get isSubheaderDisabled() {
        return this._item.disableSubHeader || false;
    }

    appendTo($container) {
        this.addClickListener();
        this.$elm.appendTo($container);
    }

    addClickListener() {
        if (!this._item.renderAsHeader) {
            this.$elm.on("click", this.onClickFromUI.bind(this));
        }
    }
}

window.mobileMenu = new MobileMenu(
    "#mainMenuMobileOverview",
    window.menuData || [],
);
