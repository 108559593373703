export class MembershipOffer {
    constructor(subscriptionButtons, basketButton) {
        const addSubButtons = document.querySelectorAll(subscriptionButtons);
        const headerBasket = document.querySelector(basketButton);

        if (addSubButtons.length === 0 || !basketButton) {
            return;
        }

        this.init(addSubButtons, headerBasket);
    }

    init(addSubButtons, headerBasket) {
        addSubButtons.forEach((button) => {
            button.addEventListener("click", (e) => {
                const el = e.target;
                const id = el.dataset.id;
                const offerKey = el.dataset.offer;
                const buttonText = el.dataset.buttonText;
                const buttonTextSelected = el.dataset.buttonTextSelected;
                const membershipSwiperSlide = el.closest(".swiper-slide");
                const addedToBasketToast = document.querySelector(
                    `.lux-toast.membership-type-${id}`,
                );

                if (!headerBasket.classList.contains("active")) {
                    headerBasket.classList.add("active");
                    headerBasket
                        .querySelector(".cart-amount")
                        .classList.add("sub-only");
                }

                luxPopup.toastShow(addedToBasketToast);
                document
                    .querySelector(".memberships-slider")
                    .classList.add("membership-selected");

                document
                    .querySelectorAll(".memberships-slider .swiper-slide")
                    .forEach((el) => el.classList.remove("selected"));

                addSubButtons.forEach((el) => {
                    el.classList.remove("disabled");
                    el.innerHTML = buttonText;
                });

                el.classList.add("disabled");
                el.innerHTML = buttonTextSelected;

                if (membershipSwiperSlide) {
                    membershipSwiperSlide.classList.add("selected");
                }

                fetch(`/membership/offer/${id}/${offerKey}`)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.success !== true) {
                            el.innerHTML = "Error";
                        }
                    });
            });
        });
    }
}
