export default class LPEvent {
    constructor(key) {
        this._key = key;
    }

    bind(handler) {
        $(this).on(this._key, handler);
    }

    trigger(data) {
        $(this).trigger(this._key, data);
    }
}
