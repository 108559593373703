export function revealReviewForm(e) {
    const starElement = e;
    const form = starElement.closest("form");
    const reviewExpanded = form.querySelector(
        ".lux-product-review .lux-product-rating-review",
    );

    if (!starElement.classList.contains("selected")) {
        reviewExpanded.classList.add("open");
    }
}

window.revealReviewForm = revealReviewForm;
