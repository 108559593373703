import $ from "jquery";

const header = {
    timer: null,

    init() {
        $(".main-menu li").hover(
            function (e) {
                if ($(".search-result-overlay").is(":visible")) return;
                const id = $(this).attr("data-id");

                header.timer = setTimeout(() => {
                    $(".main-menu-overlay").show();
                    $(".main-menu-section").hide();
                    $(`.main-menu-section[data-id=${id}]`).show();
                }, 100);
            },
            () => {
                clearTimeout(header.timer);
            },
        );

        $(".main-menu-overlay").hover((e) => {
            $(".main-menu-overlay").show();
        });

        $("ul.main-menu, .main-menu-overlay").mouseleave(() => {
            $(".main-menu-overlay").hide();
        });
    },
};
window.header = header;
$(() => {
    header.init();
});

$(".v3-overlay.main-menu-overlay .main-menu-section").mouseover(function () {
    const dataId = $(this).attr("data-id");

    $(".main-menu .nav-item").each(function () {
        if (dataId == $(this).attr("data-id")) {
            $(this).addClass("active-hover");
        }
    });
});

$(".v3-overlay.main-menu-overlay .main-menu-section").mouseleave(function () {
    const dataId = $(this).attr("data-id");

    $(".main-menu .nav-item").each(function () {
        if (dataId == $(this).attr("data-id")) {
            $(this).removeClass("active-hover");
        }
    });
});

export default header;
