const luxExpandableToggles = document.querySelectorAll(
    "*[data-toggle='lux-expandable'] .lux-expandable-btn",
);
const luxExpandableWrappers = document.querySelectorAll(
    ".lux-expandable-wrapper",
);

luxExpandableToggles.forEach(function (toggle) {
    const expandableWrapper = toggle.closest(".lux-title.expandable");

    expandableWrapper.addEventListener("click", function (e) {
        e.stopPropagation();

        expandableWrapper.classList.toggle("active");
    });
});
