const luxDropdownToggles = document.querySelectorAll(
    "*[data-toggle='lux-dropdown'] .lux-dropdown-btn",
);
const luxDropdownWrappers = document.querySelectorAll(".lux-dropdown-wrapper");

luxDropdownToggles.forEach(function (toggle) {
    const dropdownWrapper = toggle.closest(".lux-dropdown-wrapper");

    toggle.addEventListener("click", function (e) {
        e.stopPropagation();

        luxDropdownWrappers.forEach(function (wrapper) {
            wrapper.classList.remove("active");
        });

        dropdownWrapper.classList.toggle("active");
    });

    document.addEventListener("click", function (e) {
        if (!toggle.contains(e.target)) {
            dropdownWrapper.classList.remove("active");
        }
    });
});
