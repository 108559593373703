import $ from "jquery";

(() => {
    const menuShrinker = {
        $document: null,
        $window: null,
        $headerFixed: null,
        $submenu: null,
        lastScrollTop: null,
        delta: null,
        $shrinkingElement: null,
        init() {
            this.$document = $(document);
            this.$window = $(window);
            this.$headerFixed = $("body>header");
            this.$body = $("body");
            this.$submenu = $(".v3-overlay.main-menu-overlay");
            this.lastScrollTop = 0;
            this.delta = 100;
            this.$shrinkingElement = $(
                "header .shrinking-element, .lux-streamers",
            );
            this.shrinkListenerBinder("on");
        },
        shrink() {
            const _this = window.menuShrinker;

            if (!_this.$headerFixed.hasClass("shrink")) {
                _this.$headerFixed.addClass("shrink");
                _this.$body.addClass("header-shrink");

                if (!_this.$shrinkingElement.hasClass("active")) {
                    _this.$shrinkingElement.addClass("active");

                    _this.$shrinkingElement.slideUp(() => {
                        _this.$shrinkingElement.removeClass("active");
                    });
                }
            }
        },
        unShrink() {
            const _this = window.menuShrinker;

            if (_this.$headerFixed.hasClass("shrink")) {
                _this.$headerFixed.removeClass("shrink");
                _this.$body.removeClass("header-shrink");

                if (!_this.$shrinkingElement.hasClass("active")) {
                    _this.$shrinkingElement.addClass("active");

                    _this.$shrinkingElement.slideDown(() => {
                        _this.$shrinkingElement.removeClass("active");
                    });
                }
            }
        },
        shrinkListener() {
            const _this = window.menuShrinker;
            const scrollTop = _this.$document.scrollTop();

            if (Math.abs(_this.lastScrollTop - scrollTop) >= _this.delta) {
                if (scrollTop > _this.lastScrollTop) {
                    _this.shrink();
                } else {
                    _this.unShrink();
                }
                _this.lastScrollTop = scrollTop;
            }
        },
        shrinkListenerBinder(act) {
            const doAct = act === "on" ? "on" : "off";
            this.$document[doAct]("scroll", this.shrinkListener);
            this.$document[doAct]("resize", this.unShrink);
            this.$headerFixed[doAct]("mouseenter focus", this.unShrink);
            this.$headerFixed[doAct]("a input button").on(
                "focus",
                this.unShrink,
            );
        },
    };

    menuShrinker.init();

    window.menuShrinker = menuShrinker;
})();
