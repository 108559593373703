document.addEventListener("DOMContentLoaded", function () {
    const modalTriggers = document.querySelectorAll(
        '[data-toggle="lux-popup"][data-target="lux-modal-membership-qr-code"]',
    );

    if (modalTriggers.length > 0) {
        modalTriggers.forEach(function (trigger) {
            trigger.addEventListener("click", function () {
                // Fetch the QR code when the modal opens
                fetchQRCode(trigger);
            });
        });
    }

    function fetchQRCode() {
        const membershipModal = document.getElementById(
            "lux-modal-membership-qr-code",
        );

        if (membershipModal) {
            const qrCodeDisplay = membershipModal.querySelector(
                "#membership-qr-code",
            );

            fetch("/generate-qrcode")
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    // Get the response as text (SVG)
                    return response.text();
                })
                .then((svg) => {
                    // Insert QR code SVG
                    qrCodeDisplay.innerHTML = svg;
                })
                .catch((error) => {
                    console.error("Error fetching QR code:", error);
                    qrCodeDisplay.innerHTML =
                        "<p>Could not generate QR code.</p>";
                });
        }
    }
});
