import $ from "jquery";
import LPEvent from "../classes/LPEvent";

const basket = {
    onChanged: new LPEvent("basket:updated"),
    addProduct(product_slug, membership = 1, callback = null) {
        $.ajax({
            method: "POST",
            url: `/add-to-cart-json/${product_slug}/${membership}`,
            data: { _token: window.CSRF_TOKEN },
        }).done((result) => {
            basket.completed(result);
            basket.updateCartAmount(1);

            if (typeof callback == "function") {
                callback(result);
            }
        });
    },
    completed(result) {
        const toasts = result.toasts;
        const container = document.querySelector(
            ".lux-toasts-container .toasts-added-to-cart",
        );

        for (let i = 0; i < toasts.length; i++) {
            container.innerHTML += toasts[i];
        }

        const allToasts = container.querySelectorAll(".lux-toast");
        const lastToast = container.querySelector(".lux-toast:last-child");

        setTimeout(function () {
            allToasts.forEach(function (toast) {
                if (toast !== null) {
                    toast.classList.remove("active");
                }
            });

            lastToast.classList.add("active");
            luxPopup.toastShow(lastToast);
        }, 100);

        this.onChanged.trigger(null);
    },
    updateCartAmount(qty) {
        $(".added-to-cart.error").each(() => {
            if (qty > 0) {
                qty = qty - 1;
            }
        });

        const basketButton = $(".header-btn.basket");
        const basketAmount = $(".header-btn.basket .cart-amount");
        if (
            !basketButton.hasClass("active") ||
            basketAmount.hasClass("sub-only")
        ) {
            basketButton.addClass("active");
            basketAmount.text(qty);

            if (basketAmount.hasClass("sub-only")) {
                basketAmount.removeClass("sub-only");
            }
        } else {
            const cartAmount = parseInt(basketAmount.html());
            basketAmount.html(cartAmount + qty);
        }
    },
    init() {},
};
window.basket = basket;

const debounce = (fn, wait) => {
    let timeout;
    return () => {
        clearTimeout(timeout);
        timeout = setTimeout(fn, wait);
    };
};

const setHeaderHeight = () =>
    document.body.style.setProperty(
        "--headerHeight",
        document.querySelector("header").offsetHeight + "px",
    );

window.addEventListener("resize", debounce(setHeaderHeight, 500));
window.addEventListener("load", setHeaderHeight);
