import Swiper from "swiper/bundle";

function scrollClasses($this, $swiperContainer) {
    if ($swiperContainer !== null) {
        if ($this.isBeginning) {
            $swiperContainer.classList.add("scroll-start");
        } else {
            $swiperContainer.classList.remove("scroll-start");
        }

        if ($this.isEnd) {
            $swiperContainer.classList.add("scroll-end");
        } else {
            $swiperContainer.classList.remove("scroll-end");
        }
    }
}

export class swiperScroll {
    constructor(id, options) {
        this._swiperId = id;
        this._initialOptions = options;
        this.initialize(options);
        this.scroll(id);
    }

    initialize(options) {
        this._swiper = new Swiper(`#${this._swiperId}`, options);
        var $swiperContainer = document.getElementById(this._swiperId);

        scrollClasses(this._swiper, $swiperContainer);
    }

    scroll(id) {
        this._swiper.on("sliderMove scroll", function () {
            var $swiperContainer = document.getElementById(id);

            scrollClasses(this, $swiperContainer);
        });
    }
}

export class swiperSlider {
    constructor(id, options) {
        this._swiperId = id;
        this._initialOptions = options;
        this.initialize(options);
        this.scroll(id);
    }

    initialize(options) {
        this._swiper = new Swiper(`#${this._swiperId}`, options);
        var $swiperContainer = document.getElementById(this._swiperId);

        scrollClasses(this._swiper, $swiperContainer);
    }

    scroll(id) {
        this._swiper.on("sliderMove", function () {
            var $swiperContainer = document.getElementById(id);

            scrollClasses(this, $swiperContainer);
        });
    }
}
